const AdserverBase = require('../adserverBase');
const Utils = require('../utils');
const DivSlot = require('../divSlot');

class XandrSlot extends DivSlot {
	tag() {
		return window.apntag?.requests?.tags?.[this.getSlotElementId()];
	}
}

const withXandr = ((fn) => {
	window.apntag = window.apntag || {};
	window.apntag.anq = window.apntag.anq || [];
	window.apntag.anq.push(() => fn(window.apntag));
});

const asSlot = (tag) => (!tag ? tag : XandrSlot.getOrCreateSlot(tag.targetId, tag.tagId || tag.invCode));

const toNum = (val) => {
	const str = `${val}`.trim();
	const asNum = parseInt(str, 10);
	return str === asNum.toString() ? asNum : null;
};

// Event handling, to support XandrAdserver.getEventIfs()
const events = {
	infoById: {}, // { [divId]: { slot, ad, isEmpty }, ... }
	cbs: {}, // { slotRenderEnded: [cb1, cb2], ... }
	// Add an event listener, example: events.add('slotRenderEnded', ({ slot, ad, isEmpty }) => { ... })
	add(type, listener) {
		events.cbs[type] = events.cbs[type] || [];
		events.cbs[type].push(listener);
	},
	remove(type, listener) {
		events.cbs[type] = events.cbs[type]?.filter((cb) => cb !== listener);
	},
	trigger(id, type) {
		const info = events.infoById[id];
		if (info) {
			events.cbs[type]?.forEach((cb) => Utils.withCatch(() => cb(info)));
		}
	},
	onSlotsDestroyed() {
		events.infoById = {};
	},
	handle(ad, ev, cb) {
		const info = events.infoById[ad?.targetId];
		if (info) {
			cb?.(info);
		}
		events.trigger(ad?.targetId, ev);
	},
	slotRequested({ slot }) {
		const id = slot.getSlotElementId();
		events.infoById[id] = { slot };
		events.trigger(id, 'slotRequested');
	},
	listeners: {
		adAvailable: (ad) => {
			events.handle(ad, 'slotResponseReceived', (info) => Utils.assign(info, { ad, isEmpty: false }));
		},
		adNoBid: (ad) => {
			events.handle(ad, 'slotResponseReceived', (info) => Utils.assign(info, { ad, isEmpty: true }));
		},
		adLoaded: (ad) => {
			events.handle(ad, 'slotRenderEnded');
			const { keywords: kws } = events.fstAds.slotByTargetId(ad.targetId)?.tag?.() || {};
			let adId;
			if (kws) {
				adId = kws.HB_ADID || kws.hb_adid || null;
			}
			window.relevantDigital.registerRenderedDivId(ad.targetId, adId);
		},
	},
	init(fstAds) {
		events.fstAds = fstAds;
		Utils.entries(events.listeners).forEach(([ev, cb]) => fstAds.apnCall('onEvent', ev, cb));
	},
};

class XandrAdserver extends AdserverBase {
	adUnitPathsFromUnitInternal({ apnIds }) {
		return apnIds;
	}

	shouldConvertPathToLowercase() {
		return false;
	}

	getAmazonIntegrationInfo() {
		return {
			adServerName: 'appnexus', // config.adServer
			useSetDisplayBids: true,
		};
	}

	get floorProps() {
		return {
			bucketAdjustFloors: true,
			canSetFloors: true,
		};
	}

	getType() {
		return 'appnexus';
	}

	init(__, doneCb) {
		withXandr((apntag) => {
			this.apntag = apntag;
			doneCb();
		});
	}

	doFirstTimeInit() {
		this.init(null, () => events.init(this));
	}

	getEventIfs() {
		return { add: events.add, remove: events.remove };
	}

	isAmazonAd({ ad }) {
		return ad?.banner?.content?.includes?.('.apstag.renderImp(');
	}

	static destroySlots() {
		window.apntag?.clearRequest?.();
		events.onSlotsDestroyed();
	}

	/**
	 * It is unfortunately necessary to overwrite apntag.getTag() with a version that accepts ad unit codes
	 * instead of element ids. This is needed for iframe-resizing with safe-frames, as the function in Prebid
	 * named 'getAstElementId' that locates the element is assuming the ad unit code and div-id are identical.
	 */
	static initInjections() {
		if (XandrAdserver.initInjectionsDone) {
			return;
		}
		XandrAdserver.initInjectionsDone = true;
		const { apntag } = window;
		const orgGetTag = apntag.getTag;
		apntag.getTag = (targetId, ...rest) => {
			const res = orgGetTag.call(apntag, targetId, ...rest);
			if (res) {
				return res;
			}
			const id = AdserverBase.codeToId[targetId];
			if (id) {
				return orgGetTag.call(apntag, id, ...rest);
			}
			return res; // we failed
		};
	}

	getSlots() {
		return Utils.values(this.apntag?.requests?.tags || {}).map(asSlot);
	}

	oneTimePageSetup() {
		const { member } = this;
		if (member) {
			this.apntag.setPageOpts({ member });
		}
	}

	slotByTargetId(targetId) {
		return asSlot(this.apntag.requests?.tags[targetId]);
	}

	createSlot({ divId: targetId, path, sizes }, settings = {}) {
		const p = {
			tagId: settings.tagId || toNum(path),
			member: settings.member || this.member,
			sizes,
			targetId,
		};
		p.invCode = settings.invCode || (p.tagId ? null : path);
		if (!p.tagId && !(p.invCode && p.member)) {
			console.error('Incomplete parameters for XandrAdserver.createSlot()');
			return null;
		}
		for (const key in p) {
			if (!p[key]) {
				delete p[key];
			}
		}
		this.apnCall('defineTag', Utils.assign(p, settings));
		return this.slotByTargetId(targetId);
	}

	apnCall(fnName, ...args) {
		const fn = this.auction.apntagCalls?.[fnName];
		return fn ? fn.call({ auction: this.auction }, ...args) : this.apntag[fnName](...args);
	}

	sendAdserverRequest({ unknownSlotsToLoad, usedUnitDatas }) {
		const { pbjs } = this.auction;
		const { apntag } = this;
		const unitDatasByCode = Utils.keyBy(usedUnitDatas, 'code');

		XandrAdserver.initInjections();

		// Hack to convert ad unit code to targetId in Xandr calls done by pbjs.setTargetingForAst()
		// Reason is that as for Xandr, Prebid assumes ad unit codes and targetId (div-ids) are the same
		// This is not always the case for us.
		const modifiedArgs = (args) => {
			const modified = [...args];
			const id = unitDatasByCode[modified[0]]?.slot?.getSlotElementId?.();
			if (id) {
				modified[0] = id;
			}
			return modified;
		};
		if (pbjs.setTargetingForAst) { // We're not 100% sure as pbjs-loading might have failed
			const FNS = ['setKeywords', 'getTag', 'modifyTag'];
			const orgFns = {};
			try {
				FNS.forEach((fn) => {
					const org = apntag[fn];
					orgFns[fn] = org;
					const newFn = (...args) => {
						try {
							// We need to temporary switch back to the original function again so that custom
							// functions in "apntagCalls" can do the normal apntag.??() calls
							apntag[fn] = org;
							return this.apnCall(fn, ...modifiedArgs(args));
						} finally {
							apntag[fn] = newFn;
						}
					};
					apntag[fn] = newFn;
				});
				this.auction.pbjsCall('setTargetingForAst', Object.keys(unitDatasByCode));
			} finally {
				Utils.assign(apntag, orgFns);
			}
		}
		const datas = [...usedUnitDatas, ...unknownSlotsToLoad.map((slot) => ({ slot }))];
		const toShow = [];
		const toRefresh = [];
		datas.forEach(({ slot, adUnit }) => {
			const tag = slot.tag();
			if (tag && typeof adUnit?.adsFloor === 'number') {
				tag.reserve = adUnit.adsFloor;
			}
			if (tag?.uuid) { // has been loaded before
				toRefresh.push(slot.getSlotElementId());
			} else {
				toShow.push(slot.getSlotElementId());
			}
			const globalTargeting = this.getGlobalTargeting();
			if (globalTargeting) {
				this.apnCall(
					'setKeywords',
					slot.getSlotElementId(),
					globalTargeting,
				);
			}
		});
		this.apnCall('loadTags', datas.map(({ slot }) => slot.getSlotElementId()));
		// The call below must be done manually as Xandr's 'adRequested' event doesn't contain any info on which
		// placement that was requested, for whatever reason - which makes it unsuitable.
		datas.forEach(events.slotRequested);
		toShow.forEach((id) => this.apnCall('showTag', id));
		if (toRefresh.length) {
			this.apnCall('refresh', toRefresh);
		}
	}
}

module.exports = XandrAdserver;
